import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { Article, HeroArticle, Page, Section } from "components";

const TemplatePost = ({
  data: {
    post: {
      body,
      frontmatter: { author, category, date, description, image, tags, title },
    },
  },
  location,
}) => {
  const seoOptions = {
    description,
    title: `${title} | ${category} Blog`,
    openGraph: {
      title,
      type: "article",
      url: location.href,
      article: {
        // authors: [
        //   `${location?.origin}/about/#${kebabCase(author)}`,
        // ],
        publishedTime: new Date(date).toISOString(),
        // modifiedTime: new Date(updated).toISOString(),
        section: category,
        // tags,
      },
      images: [
        {
          url: `${location?.origin}${image?.childImageSharp?.fluid?.src}`,
          width: 1080,
          height: 720,
          alt: title,
        },
      ],
    },
  };

  const pageOptions = {
    header: {
      color: "secondary",
    },
  };

  const headerOptions = {
    buttons: [
      {
        label: `${category} Blog`,
        path: `/blog/${category.split(" ").join("-").toLowerCase()}/`,
      },
    ],
    context: `Posted on ${date}`,
    heading: title,
    image,
  };

  return (
    <Page options={pageOptions} seo={seoOptions}>
      <HeroArticle {...headerOptions} />
      {body && (
        <Section>
          <Article content={body} />
        </Section>
      )}
    </Page>
  );
};

TemplatePost.propTypes = {
  data: PropTypes.shape({
    post: PropTypes.shape({
      body: PropTypes.string,
      fields: PropTypes.shape({
        slug: PropTypes.string,
      }),
      frontmatter: PropTypes.shape({
        author: PropTypes.string,
        category: PropTypes.string,
        date: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
              aspectRatio: PropTypes.number,
              base64: PropTypes.string,
              src: PropTypes.string,
              srcSet: PropTypes.string,
              sizes: PropTypes.string,
            }),
          }),
        }),
        tags: PropTypes.arrayOf(PropTypes.string),
        title: PropTypes.string,
      }),
    }),
  }),
};

export const postBySlugQuery = graphql`
  query PostBySlug($slug: String!) {
    post: mdx(fields: { slug: { eq: $slug } }) {
      body
      fields {
        slug
      }
      frontmatter {
        author
        category
        date(formatString: "MMMM DD, YYYY")
        description
        image {
          childImageSharp {
            fluid(maxWidth: 1280, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        tags
        title
      }
    }
  }
`;

export default TemplatePost;
